<template>
  <div class="no-access">
    <h1 class="no-access__alert">Нет доступа к сервису BestStocks.ru</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.no-access {
  display: flex;
  height: 100vh;

  &__alert {
    margin: auto;
    text-align: center;

    font-size: 20px;
    font-weight: 600;
  }
}
</style>
